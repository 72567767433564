import logo from './logo.png';
import './App.css';

import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaEnvelope } from 'react-icons/fa';
import { FaCode } from 'react-icons/fa';
import { FaServer } from 'react-icons/fa';
import { FaDesktop } from 'react-icons/fa';

function App() {

    var openProject = "";
    const showProject = (project) => {
        var transitionPeriod = 500;
        var contentElement = document.getElementById(project + "-content");
        var contentHeight = contentElement.offsetHeight - 20; // 20 is the existing padding
        var element = document.getElementById(project);

        if (openProject === project) {
            element.style.height = "0px";
            setTimeout(function() {
                element.innerHTML = "";
                element.classList.remove('project-content-show');
                element.classList.add('project-content-hide');
            }, transitionPeriod);
            openProject = "";
        } else {
            var closingElements = false;
            Array.from(document.getElementsByClassName('project-content-show')).forEach(function(e) {
                closingElements = true;
                e.style.height = "0px";
                setTimeout(function() {
                    e.innerHTML = "";
                    e.classList.remove('project-content-show');
                    e.classList.add('project-content-hide');
                }, transitionPeriod);
            });
            var waitTime = closingElements ? transitionPeriod : 1;
            setTimeout(function() {
                element.classList.remove('project-content-hide');
                element.classList.add('project-content-show');
                element.innerHTML = contentElement.innerHTML;
                element.style.height = contentHeight + "px";
            }, waitTime);
            openProject = project;
        }
    }

    const flipBox = (box) => {
        let active = document.getElementById(box).classList.contains('flip-box-active');
        if (active) {
            document.getElementById(box).classList.remove('flip-box-active');
            document.getElementById(box).classList.add('flip-box-inactive');
            setTimeout(function() {
                document.getElementById(box + "-back").classList.add('hidden');
                document.getElementById(box + "-front").classList.remove('hidden');
            }, 500);
        } else {
            document.getElementById(box).classList.remove('flip-box-inactive');
            document.getElementById(box).classList.add('flip-box-active');
            setTimeout(function() {
                document.getElementById(box + "-front").classList.add('hidden');
                document.getElementById(box + "-back").classList.remove('hidden');
            }, 500);
        }
    }

    return (
        <div className="app">
            
            <div className="app-content">
                
                <header className="app-header">
                    <h1>
                        <img src={logo} className="app-logo" alt="logo" />
                        <span className="app-title-line-1">Shoreside</span>
                        <div className="app-title-line-2">Software llc</div>
                    </h1>
                </header>

                <div className="app-content-main-border"><div className="app-content-main">
                    <div className="app-content-description">
                        <div className="social-links">
                            <a className="social-link" target="blank" href='mailto:james@shoresidesoftware.com'>
                                <FaEnvelope />
                            </a>
                            <a className="social-link" target="blank" href="https://www.linkedin.com/in/james-griffiths-91aa4195/">
                                <FaLinkedin />
                            </a>
                            <a className="social-link" target="blank" href="https://github.com/jamesrgriffiths">
                                <FaGithub />
                            </a>
                        </div>

                        <h2 className="title">Welcome to Shoreside Software!</h2>
                        I'm James, the owner and developer of this company. My journey started as a full stack developer 
                        in 2012 at a company on Long Island in New York while I was pursuing my degree in Computer 
                        Science. I earned my degree in 2013 and continued working as a full stack developer at the same 
                        company until 2017 when I went out as an independent contractor. I now operate as an LLC and am 
                        insured. I usually operate as an independent contractor, possibly within a client's larger team, 
                        but with my current business set up I can easily hire on sub contractors when necessary to meet 
                        client deadlines while still having all code verified by me. I love the challenges of finding 
                        better solutions, solving problems, and fine-tuning existing workflows. I offer various services 
                        from trouble shooting, testing, code refactoring or rebuilding, building web apps, database 
                        management, designing and setting up APIs and data flow automations, web hosting management, and 
                        everything in between. My pricing structure varies based on the scope of the project, and I'll 
                        always do my best to find a solution that can work for you. Contact me to discuss how Shoreside 
                        Software can improve your business.
                        
                        <br/><br/>

                        <span className="tech-pill tech-backend">PHP</span>
                        <span className="tech-pill tech-backend">Laravel</span>
                        <span className="tech-pill tech-backend">Java</span>
                        <span className="tech-pill tech-backend">Spring MVC</span>
                        <span className="tech-pill tech-backend">Python</span>
                        <span className="tech-pill tech-backend">ColdFusion</span>
                        <span className="tech-pill tech-backend">Node.js</span>
                        <span className="tech-pill tech-backend">MySQL</span>
                        <span className="tech-pill tech-backend">PostgreSQL</span>

                        <span className="tech-pill tech-frontend">Javascript</span>
                        <span className="tech-pill tech-frontend">Vue.js</span>
                        <span className="tech-pill tech-frontend">React</span>
                        <span className="tech-pill tech-frontend">JQuery</span>
                        <span className="tech-pill tech-frontend">HTML</span>
                        <span className="tech-pill tech-frontend">CSS</span>
                        <span className="tech-pill tech-frontend">Bootstrap</span>

                        <span className="tech-pill tech-other">Linux</span>
                        <span className="tech-pill tech-other">Docker</span>
                        <span className="tech-pill tech-other">Puppeteer</span>
                        <span className="tech-pill tech-other">Apache</span>
                        <span className="tech-pill tech-other">NGINX</span>
                        <span className="tech-pill tech-other">AWS</span>  
                        <span className="tech-pill tech-other">Digital Ocean</span>
                        <span className="tech-pill tech-other">Laravel Forge</span>                    
                        <span className="tech-pill tech-other">Stripe</span> 
                        <span className="tech-pill tech-other">8th Wall</span>
                        <span className="tech-pill tech-other">dotcom-monitor</span>
                        <span className="tech-pill tech-other">Fortify on Demand</span>
                        <span className="tech-pill tech-other">Zoho Creator</span>
                        
                    </div>

                    <div id="projects" className="projects">
                        <h2 className="title title-highlight">
                            Projects:
                            <div className="subtitle">Click below to see more detail</div>
                        </h2>

                        <ul>
                            <li onClick={() => showProject('project-agentbuzz')}>
                                AgentBuzz
                                <div id='project-agentbuzz-content' className="project-content">
                                    This was a client project where I rebuilt the 
                                    infrastructure and added in various new 
                                    capabilities. The primary functions allow a user 
                                    to purchase a subscription through a Stripe 
                                    Integration, create custom banners with text and 
                                    voice capable phone numbers and QR codes, and 
                                    then monitor incoming leads. 
                                    <br/><br/>
                                    December 2020 - November 2022
                                    <br/><br/>
                                    <span className="tech-pill tech-backend">PHP</span>
                                    <span className="tech-pill tech-backend">Laravel</span>
                                    <span className="tech-pill tech-backend">Node.js</span>
                                    <span className="tech-pill tech-backend">MySQL</span>
                                    <span className="tech-pill tech-frontend">Vue.js</span>
                                    <span className="tech-pill tech-frontend">HTML</span>
                                    <span className="tech-pill tech-frontend">CSS</span>
                                    <span className="tech-pill tech-other">Stripe</span>                             
                                </div>
                                <div id='project-agentbuzz' className="project-content-hide"></div>
                            </li>
                            <li onClick={() => showProject('project-educatius')}>
                                Educatius
                                <div id='project-educatius-content' className="project-content">
                                    This is a current client in the Education sector 
                                    where I create and manage various automations for 
                                    transferring data between various systems. I 
                                    utilize several small APIs, Puppeteer automations, 
                                    Python and Node JS scripts, and have it all running 
                                    off of a PHP Laravel and MySQL backend. I also 
                                    built a small interface for managing some pieces 
                                    of the system. 
                                    <br/><br/>
                                    February 2018 - Present
                                    <br/><br/>
                                    <span className="tech-pill tech-backend">PHP</span>
                                    <span className="tech-pill tech-backend">Laravel</span>
                                    <span className="tech-pill tech-backend">Python</span>
                                    <span className="tech-pill tech-backend">Node.js</span>
                                    <span className="tech-pill tech-backend">MySQL</span>
                                    <span className="tech-pill tech-frontend">Javascript</span>
                                    <span className="tech-pill tech-frontend">HTML</span>
                                    <span className="tech-pill tech-frontend">CSS</span>
                                    <span className="tech-pill tech-frontend">Bootstrap</span>
                                    <span className="tech-pill tech-other">Zoho Creator</span>
                                    <span className="tech-pill tech-other">Puppeteer</span>
                                    <span className="tech-pill tech-other">APIs</span>
                                </div>
                                <div id='project-educatius' className="project-content-hide"></div>
                            </li>
                            <li onClick={() => showProject('project-mc3')}>
                                MC3
                                <div id='project-mc3-content' className="project-content">
                                    This is a current client where I collaborate with 
                                    a team to create interactive experiences for events 
                                    in the healthcare sector. We create various modules 
                                    and have a management console that we update regularly. 
                                    I'm involved in the full stack operation here including 
                                    testing and evaluation of projects.
                                    <br/><br/>
                                    August 2022 - Present
                                    <br/><br/>
                                    <span className="tech-pill tech-backend">PHP</span>
                                    <span className="tech-pill tech-backend">MySQL</span>
                                    <span className="tech-pill tech-frontend">Javascript</span>
                                    <span className="tech-pill tech-frontend">JQuery</span>
                                    <span className="tech-pill tech-frontend">HTML</span>
                                    <span className="tech-pill tech-frontend">CSS</span>
                                    <span className="tech-pill tech-other">8th Wall</span>
                                    <span className="tech-pill tech-other">dotcom-monitor</span>
                                    <span className="tech-pill tech-other">Fortify on Demand</span>
                                </div>
                                <div id='project-mc3' className="project-content-hide"></div>
                            </li>
                            <li onClick={() => showProject('project-shoresidesoftware')}>
                                Shoreside Software
                                <div id='project-shoresidesoftware-content' className="project-content">
                                    The site you're on. It's a simple site 
                                    I built over a weekend to update my portfolio and 
                                    get myself more aquainted with React.
                                    <br/><br/>
                                    Last Updated September 2024
                                    <br/><br/>
                                    <span className="tech-pill tech-backend">Node.js</span>
                                    <span className="tech-pill tech-frontend">React</span>
                                    <span className="tech-pill tech-frontend">HTML</span>
                                    <span className="tech-pill tech-frontend">CSS</span>
                                    <span className="tech-pill tech-other">Docker</span>
                                </div>
                                <div id='project-shoresidesoftware' className="project-content-hide"></div>
                            </li>
                            {/* <li onClick={() => showProject('project-supersimplecheckbook')}>
                                Super Simple Checkbook
                                <div id='project-supersimplecheckbook-content' className="project-content">
                                    This is a web app I built for finance management. 
                                    I built in with a typical PHP Laravel stack with 
                                    a MySQL database. It has not been updated in a 
                                    several years but is still available with limited 
                                    functionality below:
                                    <br/><br/>
                                    <a className='app-link' href='https://www.supersimplecheckbook.com' target='blank'>supersimplecheckbook.com</a>
                                    <br/><br/>
                                    Last Updated February 2019
                                    <br/><br/>
                                    <span className="tech-pill tech-backend">PHP</span>
                                    <span className="tech-pill tech-backend">Laravel</span>
                                    <span className="tech-pill tech-backend">Node.js</span>
                                    <span className="tech-pill tech-backend">MySQL</span>
                                    <span className="tech-pill tech-frontend">Javascript</span>
                                    <span className="tech-pill tech-frontend">JQuery</span>
                                    <span className="tech-pill tech-frontend">HTML</span>
                                    <span className="tech-pill tech-frontend">CSS</span>
                                    <span className="tech-pill tech-frontend">Bootstrap</span>
                                </div>
                                <div id='project-supersimplecheckbook' className="project-content-hide"></div>
                            </li> */}
                            <li onClick={() => showProject('project-laravellemon')}>
                                Laravel Lemon
                                <div id='project-laravellemon-content' className="project-content">
                                    Laravel Lemon is a skeleton (or scaffolding) 
                                    application I created on top of the PHP Laravel 
                                    framework. It has several components that I have 
                                    found useful and in the past would end up 
                                    replicating in multiple projects. A lot of the 
                                    functionality could be separated out into packages, 
                                    and I may do that in the future, but for now this 
                                    functions only as a starting point application.<br/><br/>
                                    The core functionality is a request and error 
                                    logging system, an organization - user type - user 
                                    management system, and a route access control system. 
                                    All of this functionality has basic user interface 
                                    management consoles built with VUE utilizing a child 
                                    component structure to create more modular and easily 
                                    updated interfaces. The system also uses the repository 
                                    pattern with facades and has several useful custom 
                                    functions built into the base repositories. You can 
                                    view the code on the github repository below:
                                    <br/><br/>
                                    <a className='app-link' href='https://github.com/jamesrgriffiths/laravellemon' target='blank'>Laravel Lemon Github Project</a>
                                    <br/><br/>
                                    Last Updated January 2023
                                    <br/><br/>
                                    <span className="tech-pill tech-backend">PHP</span>
                                    <span className="tech-pill tech-backend">Laravel</span>
                                    <span className="tech-pill tech-backend">Node.js</span>
                                    <span className="tech-pill tech-frontend">Vue.js</span>
                                    <span className="tech-pill tech-frontend">HTML</span>
                                    <span className="tech-pill tech-frontend">CSS</span>
                                </div>
                                <div id='project-laravellemon' className="project-content-hide"></div>
                            </li>
                            <li onClick={() => showProject('project-lemoncomponents')}>
                                Lemon Components
                                <div id='project-lemoncomponents-content' className="project-content">
                                    Lemon Components is a Vue component library for 
                                    basic website functionality and data display. It 
                                    uses sass variables for easily customizing styling 
                                    and each component is built responsively. It is at 
                                    this point in need of some dependency updates but 
                                    you can still check it out below:
                                    <br/><br/>
                                    <a className='app-link' href='https://github.com/jamesrgriffiths/lemon-components' target='blank'>Lemon Components Github Project</a>
                                    <br/><br/>
                                    Last Updated August 2020
                                    <br/><br/>
                                    <span className="tech-pill tech-backend">Node.js</span>
                                    <span className="tech-pill tech-frontend">Vue.js</span>
                                    <span className="tech-pill tech-frontend">HTML</span>
                                    <span className="tech-pill tech-frontend">CSS</span>
                                </div>
                                <div id='project-lemoncomponents' className="project-content-hide"></div>
                            </li>
                            {/* <li onClick={() => showProject('project-findyourchurch')}>
                                Find Your Church
                                <div id='project-findyourchurch-content' className="project-content">test 2</div>
                                <div id='project-findyourchurch' className="project-content-hide"></div>
                            </li> */}
                        </ul>
                    </div>

                    <div id="pricing" className="pricing">
                        <h2 className="title title-highlight">
                            Pricing:
                            <div className="subtitle">Click below to see more detail</div>
                        </h2>

                        <div className="flip-boxes">
                            
                            <div id='pricing-1' className='flip-box' onClick={() => flipBox('pricing-1')}>
                                <div id='pricing-1-front' className='flip-box-front'>
                                    <div className='flip-box-header'>Contract Rate</div>
                                    <div className='flip-box-front-content'>
                                        <FaCode className='flip-box-icons' />
                                        <br/>
                                        <a className='app-link' target="blank" href='mailto:james@shoresidesoftware.com'>
                                            james@shoresidesoftware.com
                                        </a>
                                    </div>
                                    <div className='flip-box-footer'>$90 Hourly</div>
                                </div>
                                <div id='pricing-1-back' className='flip-box-back hidden'>
                                    <div className='flip-box-header'>Contract Rate</div>
                                    <div className='flip-box-back-content'>
                                        Our standard hourly rate for custom contract based work. We can work with you on 
                                        a number of projects varying in size from simple bug fixes to entire custom 
                                        application builds and everything in between. We will spend time going through 
                                        your specific goals and requirements and tailor a solution to fit. This rate is 
                                        flexible based on the scope of the project - larger projects requiring more 
                                        regular work over a period of time will reduce the cost. We can take on your 
                                        projects independently or I (James) can join your existing team to work together 
                                        towards better solutions. Contact us about your next project!
                                    </div>
                                </div>
                            </div>
                            
                            <div id='pricing-2' className='flip-box' onClick={() => flipBox('pricing-2')}>
                                <div id='pricing-2-front' className='flip-box-front'>
                                    <div className='flip-box-header'>Project Development</div>
                                    <div className='flip-box-front-content'>
                                        <FaDesktop className='flip-box-icons' />
                                        <a className='app-link' target="blank" href='mailto:james@shoresidesoftware.com'>
                                            james@shoresidesoftware.com
                                        </a>
                                    </div>
                                    <div className='flip-box-footer'>$500+ Project</div>
                                </div>
                                <div id='pricing-2-back' className='flip-box-back hidden'>
                                    <div className='flip-box-header'>Project Development</div>
                                    <div className='flip-box-back-content'>
                                        For fully custom web applications we can complete your project at a set rate. 
                                        These rates are variable and can be customized to your specific needs, We have 
                                        some basic pricing listed here for reference but please contact us to get a 
                                        more detailed quote.<br/><br/>
                                        The base pricing of $500 is for a responsive portfolio style website with 
                                        1-3 pages and up to 3 revisions. Additional standard options are listed below.
                                        <br/>
                                        <ul>
                                            <li>Additional page: $200</li>
                                            <li>Data-driven feature: $500+</li>
                                            <li>User account system: $900</li>
                                            <li>File upload system: $1200-$2000</li>
                                            <li>Storefront system: $1500-$3000</li>
                                            <li>3rd party server setup: $200-$500</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div id='pricing-3' className='flip-box' onClick={() => flipBox('pricing-3')}>
                                <div id='pricing-3-front' className='flip-box-front'>
                                    <div className='flip-box-header'>Hosting & Maintenance</div>
                                    <div className='flip-box-front-content'>
                                        <FaServer className='flip-box-icons' />
                                        <a className='app-link' target="blank" href='mailto:james@shoresidesoftware.com'>
                                            james@shoresidesoftware.com
                                        </a>
                                    </div>
                                    <div className='flip-box-footer'>$150+ Monthly</div>
                                </div>
                                <div id='pricing-3-back' className='flip-box-back hidden'>
                                    <div className='flip-box-header'>Hosting & Maintenance</div>
                                    <div className='flip-box-back-content'>
                                        Have Shoreside Software handle your server hosting and maintenance! Each project 
                                        is unique so please contact us about your specific needs. Below you will see 
                                        some standard rates.<br/><br/>
                                        
                                        Base Hosting - $150/month:
                                        <ul>
                                            <li>20% discount on project development</li>
                                            <li>50% discount on 1 hour of updates</li>
                                            <li>Server setup and hosting for light traffic</li>
                                            <li>SSL certificate management</li>
                                            <li>Regular stability checks</li>
                                            <li>Regular software and plugin updates</li>
                                        </ul>

                                        Hosting Add-Ons
                                        <ul>
                                            <li>Higher traffic: $40+</li>
                                            <li>Higher processing demands: $40+</li>
                                            <li>File storage server: $50+</li>
                                            <li>Storefront management: $100+</li>
                                        </ul>
                                        
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                    </div>

                </div></div>

            </div>

            <footer>
                &copy;2024 Shoreside Software LLC - james@shoresidesoftware.com
            </footer>

        </div>
    );
}

export default App;
